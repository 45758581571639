<template>
  <section class="offer">
    <div class="offer__content">
      <div class="container">
        <div class="offer__title" v-if="title" v-html="title"> </div>
        <div class="offer__description" v-if="subTitle" v-html="subTitle"></div>
        <div class="offer__button-wrapper">
          <Button parent-class="offer__button" v-on:click="openPopup()" v-if="btn">{{ btn }}</Button>
        </div>
      </div>
    </div>
    <div
      class="offer__video"
      v-if="content.offer_2_id_poster || content.offer_2_video"
      data-aos="custom-img"
      data-aos-duration="1200"
      data-aos-delay="3000">
      <a
        class="offer__video-link"
        v-bind:href="content.offer_2_video_link ? content.offer_2_video_link : null"
        :data-fancybox="content.offer_2_video_link ? 'video' : null">
        <video class="offer__video-file" muted loop autoplay :poster="content.offer_2_id_poster">
          <source :src="content.offer_2_video" type="video/mp4" />
        </video>
        <span class="offer__play" v-if="content.offer_2_video_link">
          <span class="offer__play-button">
            <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 19.0657V0.934259L14.0986 10L0.5 19.0657Z" fill="CCCCCC" />
            </svg>
          </span>
        </span>
      </a>
    </div>
  </section>
</template>

<script>
import Button from "@/components/element/ButtonApp.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  components: {
    Button,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.offer3,
      title: "",
      subTitle: "",
      btn: "",
      metka: "",
    };
  },
  methods: {
    openPopup() {
      if (this.metka) {
        this.$metrika.reachGoal(this.metka);
      }

      this.$store.commit("updateOrderPopup", {
        status: true,
        data: this.content.offer_2_popup,
        name: null,
      });
    },
  },
  mounted() {
    const url = new URL(window.location.href);

    for (let i = 0; i < this.content.offer_2.length; i++) {
      if (this.content.offer_2[i].offer_2_tag === url.hash) {
        this.title = this.content.offer_2[i].offer_2_title;
        this.subTitle = this.content.offer_2[i].offer_2_desc;
        this.btn = this.content.offer_2[i].offer_2_button;
        this.metka = this.content.offer_2[i].offer_2_button_metka;

        break;
      }
    }

    // Воспроизведение видео после анимации появления

    let animationElement = document.querySelector(".offer__video");
    let videoElement = document.querySelector(".offer__video-file");
    videoElement.play();

    let context = this.content;

    animationElement.addEventListener("transitionend", function () {
      let videoElement = document.querySelector(".offer__video-file");
      videoElement.play();

      if (context.offer_2_video_link) {
        let videoPlay = document.querySelector(".offer__play");
        videoPlay.classList.add("offer__play_show");
      }
    });

    if (this.content.offer_2_video_link) {
      // Инициализация Fancybox
      Fancybox.bind("data-fancybox='video'");
    }
  },
};
</script>

<style>
.offer {
  position: relative;

  width: 100%;
  padding-top: 105px;
  margin: 0 auto;
  margin-bottom: 175px;

  background-color: #ffffff;
}

.offer__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 588px;
  padding-top: 40px;
  padding-bottom: 40px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  overflow: hidden;
}

.offer__title {
  margin-bottom: 40px;

  font-family: var(--title1-fonts-family);
  font-style: normal;
  font-weight: 500;
  font-size: var(--title1-fonts-size);
  line-height: var(--title1-line-height);
  color: var(--title1-color-white);
}

.offer__description {
  max-width: 565px;
  width: 100%;
  margin-left: 60px;
  margin-bottom: 30px;

  font-family: var(--subtitle1-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle1-fonts-size);
  line-height: var(--subtitle1-line-height);
  color: var(--subtitle1-color-white);
}

.offer__button {
  margin-left: 60px;
}

.offer__video {
  width: 100%;

  cursor: pointer;
}

.offer__video-file {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.offer__play {
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;

  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.4s ease-in 0.4s;
}

.offer__play_show {
  opacity: 1;
}

.offer__play::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0;

  background-color: var(--buttom-play-background-pulse);
  animation: pulse-play 1500ms ease-out 3s infinite;
}

@keyframes pulse-play {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.offer__play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding-left: 4px;

  background-color: var(--buttom-play-background-bg-play);
  transition: background-color 0.5s ease-in;
}

.offer__video:hover .offer__play-button {
  background-color: var(--buttom-play-background-bg-play-hover);
}

.offer__play-button path {
  fill: var(--buttom-play-background-play);
  transition: fill 0.5s ease-in;
}

.offer__video:hover .offer__play-button path {
  fill: var(--buttom-play-background-play-hover);
}

@media (max-width: 1919.98px) {
  .offer__content {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .offer__title {
    font-size: 64px;
  }
}

.offer__video-link {
  cursor: default;
}

.offer__video-link[data-fancybox="video"] {
  cursor: pointer;
}

@media (max-width: 1599.98px) {
  .offer {
    padding-top: 71px;
    margin-bottom: 150px;
  }

  .offer__content {
    min-height: 435px;
    padding-top: 64px;
    padding-bottom: 60px;
  }

  .offer__title {
    margin-bottom: 30px;

    font-size: 48px;
  }

  .offer__description {
    max-width: 475px;
    margin-left: 60px;
    margin-bottom: 25px;

    font-size: 18px;
    line-height: 160%;
  }

  .offer__button {
    margin-left: 60px;
  }

  .offer__play {
    width: 90px;
    height: 90px;
  }

  .offer__play-button {
    width: 60px;
    height: 60px;
  }

  .offer__play-button svg {
    width: 11px;
    height: 14px;
  }
}

@media (max-width: 1199.98px) {
  .offer {
    margin-bottom: 130px;
  }

  .offer__content {
    min-height: 344px;
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .offer__title {
    margin-bottom: 20px;

    font-size: 36px;
  }

  .offer__description {
    max-width: 403px;
    margin-left: 50px;
    margin-bottom: 20px;

    font-size: 16px;
  }

  .offer__button {
    margin-left: 50px;
  }

  .offer__play {
    width: 70px;
    height: 70px;
  }

  .offer__play-button {
    width: 55px;
    height: 55px;
  }

  .offer__play-button svg {
    width: 9px;
    height: 12px;
  }
}

@media (max-width: 991.98px) {
  .offer {
    padding-top: 60px;
    margin-bottom: 110px;
  }

  .offer__content {
    min-height: 350px;
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .offer__title {
    margin-bottom: 25px;

    font-size: 32px;
  }

  .offer__description {
    max-width: 388px;
    margin-left: 0;
    margin-bottom: 20px;

    font-size: 14px;
  }

  .offer__button {
    margin-left: 0;
  }

  .offer__play {
    width: 60px;
    height: 60px;
  }

  .offer__play-button {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 767.98px) {
  .offer {
    padding-top: 60px;
    margin-bottom: 70px;
  }

  .offer__content {
    min-height: 350px;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .offer__title {
    margin-bottom: 20px;

    font-size: 28px;
  }

  .offer__description {
    max-width: none;
  }

  .offer__button {
    margin-left: 0;
  }
}
</style>
